import React, { FC } from 'react'
import { ScreenReaderOnly, Text, Flex } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { FilterCategory as FilterCategoryType } from '../../../../services/types/services'
import { SelectedFiltersList } from './SelectedFiltersList'
import { useFindLocaleText } from '../../../../text-localisation'

const ICON_SIZE = 24

const StyledLabel = styled(Text)`
  ${s({
    textAlign: 'left',
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
  })}
`

const StyledButton = styled.button.attrs(
  ({ isSubCategory }: { isSubCategory: boolean }) => ({
    className: isSubCategory && 'isSubCategory',
  }),
)<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 64px;
  ${s({
    py: 5,
    pl: 6,
    pr: 8,
    borderBottom: '1px solid',
    borderColor: 'colorBorder03',
    color: 'colorTextLabel',
  })}

  &:hover {
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
    })}
  }

  &:active {
    ${s({
      bgcolor: 'colorBackground03',
      borderColor: 'colorBlack10',
    })}
  }

  &:focus,
  &:focus-visible {
    ${s({
      color: 'colorInteractionFocusIndicator',
    })}
  }

  &.isSubCategory {
    ${s({
      borderTop: '1px solid',
      borderColor: 'colorBorder03',
    })}

    & + &.isSubCategory,
    div:first-child + &.isSubCategory {
      ${s({
        borderTop: 'none',
        py: 0,
        my: 0,
      })}
    }
  }
`

const StyledChevronRightIcon = styled(IconSystemChevronRight)`
  flex-shrink: 0;
`

type Props = {
  filter: FilterCategoryType
  onClick: (filter: FilterCategoryType) => void
  level: number
}

export const FilterCategory: FC<Props> = ({ filter, level, onClick }) => {
  const localiseText = useFindLocaleText()

  return (
    <StyledButton
      data-testid="filter-category"
      onClick={() => onClick(filter)}
      key={filter.id}
      role="tab"
      aria-controls={`${filter.id}-filter-category-panel`}
      isSubCategory={level > 0}
    >
      <Flex flexDirection="column">
        <StyledLabel>{filter.label}</StyledLabel>
        <ScreenReaderOnly>
          {localiseText('find.filters_menu_panel.open', filter.label)}
        </ScreenReaderOnly>
        <SelectedFiltersList filter={filter} />
      </Flex>
      <StyledChevronRightIcon width={ICON_SIZE} height={ICON_SIZE} />
    </StyledButton>
  )
}
