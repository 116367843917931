import React, { FC, useEffect, useRef, useState } from 'react'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  DrawerContent,
  DrawerOverlay,
  DrawerWrapper,
  DrawerTitle,
} from './components'

const StyledStickyHeader = styled.div`
  ${s({
    position: 'sticky',
    top: 0,
    pt: 4,
    bgcolor: 'colorBackground01',
    borderBottom: '1px solid',
    borderColor: 'colorBorder03',
  })}
`

type Props = {
  /** The title of the drawer */
  title?: string
  /** The aria label value for the drawer's content */
  ariaLabel: string
  /** Optionally pass a header component to the drawer */
  header?: JSX.Element
  /** Determines if the drawer is visible */
  isOpen: boolean
  /** Callback function that is called when the drawer is closed */
  onClosed: () => void
  /** Determines the anchor position of the drawer */
  anchor?: 'top' | 'bottom' | 'left' | 'right'
  /** Optionally pass a ref to the trigger button so it can receive focus after the drawer is closed */
  triggerRef?: React.RefObject<HTMLElement>
  /** The offset number of pixels */
  offset?: number
}

export const Drawer: FC<Props> = ({
  title,
  ariaLabel,
  header,
  isOpen,
  onClosed,
  anchor,
  triggerRef,
  offset = 80,
  children,
}) => {
  const [isClosing, setClosing] = useState(false)
  const isOpenMemo = useRef(false)

  const className = `${anchor || 'right'} ${isClosing ? 'close' : 'open'}`

  const handleClosing = () => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      onClosed()
    } else {
      setClosing(true)
    }
  }

  const handleClose = () => {
    if (isClosing) {
      onClosed()
      setClosing(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      isOpenMemo.current = true
    }

    if (!isOpen && isOpenMemo.current && triggerRef?.current) {
      isOpenMemo.current = false
      triggerRef.current.focus()
    }
  }, [isOpen, triggerRef])

  useEffect(() => {
    const onKeyUp = ({ key }: KeyboardEvent) => {
      if (key === 'Escape' && isOpen) {
        handleClosing()
      }
    }
    window.addEventListener('keyup', onKeyUp)

    return () => window.removeEventListener('keyup', onKeyUp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <DrawerWrapper isOpen={isOpen}>
      {isOpen && <RemoveScrollBar />}
      <DrawerOverlay
        className={className}
        onClick={handleClosing}
        data-testid="drawer-overlay"
      />
      <DrawerContent
        data-testid="drawer-content"
        className={className}
        onAnimationEnd={handleClose}
        role="dialog"
        aria-label={ariaLabel}
        aria-modal={isOpen}
        offset={offset}
      >
        <StyledStickyHeader>
          {title && <DrawerTitle title={title} onCloseClick={handleClosing} />}
          {header && header}
        </StyledStickyHeader>
        {children}
      </DrawerContent>
    </DrawerWrapper>
  )
}
