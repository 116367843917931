import React, { FC } from 'react'
import { Flex, IconButton, Text } from '@moonpig/launchpad-components'
import { IconSystemCross } from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  ApplicableFilter,
  FilterCategory,
} from '../../../services/types/services'
import { useFindLocaleText } from '../../../text-localisation'
import { shouldShowClearButton } from '../../../utils/GalleryFiltering/shouldShowClearButton'
import { ClearButton } from '../../ClearButton'
import { FilterSource } from '../../../types'

const StyledHeader = styled(Flex)`
  ${s({
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 12,
    pr: 6,
    pl: 3,
  })}
`

type Props = {
  filterCategory: FilterCategory
  onClose: () => void
  selectedFilters: ApplicableFilter[]
}

export const Header: FC<Props> = ({
  filterCategory,
  onClose,
  selectedFilters,
}) => {
  const localiseText = useFindLocaleText()
  return (
    <StyledHeader>
      <IconButton
        icon={IconSystemCross}
        label={localiseText('find.close_filters_menu')}
        onClick={onClose}
      />
      <Text typography="bodyCopy" fontWeight="bold" textAlign="center">
        {filterCategory.label}
      </Text>
      <ClearButton
        level={1}
        category={filterCategory}
        hide={
          !shouldShowClearButton({
            category: filterCategory,
            selectedFilters,
          })
        }
        source={FilterSource.QuickFilters}
      />
    </StyledHeader>
  )
}
