import React, { FC, PropsWithChildren, useState, useEffect } from 'react'
import {
  Root as RadixDropdownMenu,
  Trigger,
  Content,
  Portal,
} from '@radix-ui/react-dropdown-menu'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Drawer } from '@moonpig/web-shared-components'
import { useIsMobile } from '@moonpig/web-shared-utils'
import { Box } from '@moonpig/launchpad-components'
import {
  ApplicableFilter,
  FilterCategory,
} from '../../../services/types/services'
import { DropDownButton } from '../DropDownButton'
import { Header } from './Header'
import { getAllSelectedFilters } from '../../../utils/GalleryFiltering/getAllSelectedFilters'
import { createMenuFooter, createMenuItems } from './createMenu'
import { FilterSource } from '../../../types'
import { ResultsButton } from '../../FiltersMenu/components'

const MENU_OFFSET_PX = '348px'
const MENU_MIN_WIDTH_PX = '375px'
const MENU_MAX_HEIGHT_PX = '480px'

const StyledDropdownContent = styled(Content)`
  position: relative;
  overflow-y: auto;
  overscroll-behavior: contain;
  max-height: min(calc(100vh - ${MENU_OFFSET_PX}), ${MENU_MAX_HEIGHT_PX});
  z-index: 1050;
  ${s({
    bgcolor: 'colorBackground01',
    minWidth: MENU_MIN_WIDTH_PX,
    boxShadow: 4,
    borderRadius: 4,
    borderColor: 'colorBorder03',
    border: '2px solid',
  })};
`

type Props = PropsWithChildren<{
  filterCategory: FilterCategory
  selectedFilters: ApplicableFilter[]
  onChange: () => void
  toggleFilter: ({
    filter,
    select,
    source,
  }: {
    filter: ApplicableFilter
    select: boolean
    source: FilterSource
  }) => void
  count: number
  loading: boolean
}>

export const DropDownMenu: FC<Props> = ({
  filterCategory,
  selectedFilters,
  onChange,
  toggleFilter,
  count,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownButtonRef = React.createRef<HTMLButtonElement>()
  const isMobile = useIsMobile()

  const dropDownMenuContent = (
    <>
      {createMenuItems(filterCategory.children, toggleFilter, isMobile)}
      {createMenuFooter({
        filterCategory,
        selectedFilters,
        isMobile,
        resultsButton: (
          <ResultsButton
            onClick={() => setIsOpen(!isOpen)}
            loading={loading}
            resultsCount={count}
          />
        ),
      })}
    </>
  )

  useEffect(() => {
    onChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return isMobile ? (
    <Box pb={4}>
      <DropDownButton
        label={filterCategory.label}
        isOpen={isOpen}
        count={getAllSelectedFilters(filterCategory.children).length}
        onClick={() => setIsOpen(!isOpen)}
        ref={dropdownButtonRef}
      />
      <Drawer
        data-testid="filter-drawer"
        ariaLabel={`${filterCategory.label} filters`}
        anchor="bottom"
        isOpen={isOpen}
        offset={48}
        onClosed={() => setIsOpen(!isOpen)}
        triggerRef={dropdownButtonRef}
        header={
          <Header
            filterCategory={filterCategory}
            onClose={() => setIsOpen(!isOpen)}
            selectedFilters={selectedFilters}
          />
        }
      >
        {dropDownMenuContent}
      </Drawer>
    </Box>
  ) : (
    <Box py={4}>
      <RadixDropdownMenu
        onOpenChange={() => {
          setIsOpen(!isOpen)
        }}
        open={isOpen}
      >
        <Trigger asChild>
          <DropDownButton
            label={filterCategory.label}
            isOpen={isOpen}
            count={getAllSelectedFilters(filterCategory.children).length}
            onClick={() => {}}
          />
        </Trigger>
        <Portal>
          <StyledDropdownContent sideOffset={8} align="start">
            {dropDownMenuContent}
          </StyledDropdownContent>
        </Portal>
      </RadixDropdownMenu>
    </Box>
  )
}
