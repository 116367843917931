import React, { FC, memo, useCallback } from 'react'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { getParentDepartment } from '@moonpig/web-core-utils'
import {
  trackUIEvent,
  createProductViewedEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { Region } from '@moonpig/web-core-types'
import { Box, Flex } from '@moonpig/launchpad-components'
import {
  ProductClickedEvent,
  AddedToBasketEvent,
  Status,
  PromoTileData,
} from '@moonpig/web-shared-products'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { styled } from '@moonpig/launchpad-utils'
import { getDeviceType } from '@moonpig/web-shared-utils'
import { SHOW_RUDE_TOGGLE } from '@moonpig/web-core-brand/config'
import { LoadMoreType } from '../types'
import { loadMoreGAEventDetails } from '../../analytics/GAEvents'
import { ProductGridWithPaging, GalleryProduct } from '../ProductGridWithPaging'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'
import { SearchFiltersFacet } from '../../pages/GalleryPage/types'
import { getSortByOptions, useSetUIHCookie } from '../../utils'
import { useSearchStore } from '../../store/SearchStore'
import { RudeToggle, SortButton } from '../FiltersMenu'

const StyledBox = styled(Box)`
  position: relative;
`

type ProductGridWithTrackingProps = {
  totalCount: number
  pageTitle: string
  metaTitle: string
  details: {
    region: Region
    url: string
    searchTerm: string
    department: DepartmentsEnum[]
  }
  products: GalleryProduct[]
  loadMoreProducts?: (loadMoreType: LoadMoreType) => Promise<boolean>
  presetFacets: SearchFiltersFacet[]
  experiments?: { [key: string]: string } | undefined
  isInternalUser?: boolean
  onAddedToBasket: (e: AddedToBasketEvent) => void
  pageType: string
  customerId?: string
  isZeroSearchResults?: boolean
  groupCardProject?: string
  promoTile?: PromoTileData
}

export const ProductGridWithTrackingComponent: FC<
  ProductGridWithTrackingProps
> = ({
  totalCount,
  metaTitle,
  details,
  products,
  loadMoreProducts,
  experiments,
  onAddedToBasket,
  isInternalUser = false,
  pageType,
  customerId,
  isZeroSearchResults = false,
  groupCardProject,
  promoTile,
}) => {
  const [{ loading }] = useProductListingPageContext()
  const { region, searchTerm, department } = details
  const localiseText = useFindLocaleText()
  const { setUIHCookie } = useSetUIHCookie()
  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const sortValue = useSearchStore(store => store.sortValue)
  const resultsLoading = useSearchStore(store => store.resultsLoading)
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const preAppliedFilters = useSearchStore(store => store.preAppliedFilters)

  const productsLoading = filtersMenuOpen ? loading || resultsLoading : loading

  const onProductClicked = useCallback(
    async ({ product }: ProductClickedEvent) => {
      setUIHCookie({ product })
    },
    [setUIHCookie],
  )

  const onProductFirstClick = useCallback(
    async ({ product }: ProductClickedEvent) => {
      const { mnpg_ui_events_api_correlation_id: correlationId } =
        getBrowserCookies()
      if (process.env.API_URL) {
        try {
          await trackUIEvent(
            createProductViewedEvent({
              productId: product.id,
              searchTerm,
              department,
              correlationId,
              filters: [
                ...selectedFilters.map(x => ({
                  facetKey: x.id,
                })),
                ...preAppliedFilters.map(x => ({
                  facetKey: x.key,
                })),
              ],
              store: region.toUpperCase(),
              ...(experiments && { metaData: { experiments } }),
              source: getDeviceType(),
              customerId,
            }),
          )
        } catch (error) {
          // @eslint-disable-next-line @typescript-eslint/no-empty
        }
      }
    },
    [
      searchTerm,
      department,
      selectedFilters,
      preAppliedFilters,
      region,
      experiments,
      customerId,
    ],
  )

  const onAddedToBasketWithTracking = React.useCallback(
    (e: AddedToBasketEvent) => {
      onAddedToBasket({
        ...e,
        componentName: 'product grid',
      })
    },
    [onAddedToBasket],
  )

  const onLoadMoreCallback = React.useCallback(
    async (type: LoadMoreType) => {
      if (type === LoadMoreType.CLICK) {
        trackGAEvent(loadMoreGAEventDetails(products.length))
      }
      return (
        (loadMoreProducts && loadMoreProducts(type)) ||
        /* istanbul ignore next */ true
      )
    },
    [loadMoreProducts, products.length],
  )

  const parentDepartment = getParentDepartment(department[0])

  const isCards = parentDepartment === 'ALL_CARDS'
  const enableRudeToggle = SHOW_RUDE_TOGGLE && isCards

  const sortByOptions = getSortByOptions({
    isCards,
    localiseText,
  })

  return (
    <StyledBox>
      <Status
        loading={{
          status: productsLoading,
          fixed: false,
          zIndex: 999,
        }}
      />
      <Flex
        justifyContent="space-between"
        pl={{ xs: 6, md: 7, lg: 10, xxl: 12 }}
        pr={{ xs: 3, md: 7, xxl: 9 }}
        flexDirection={isCards ? 'row' : 'row-reverse'}
        mb={4}
      >
        {!isZeroSearchResults && (
          <SortButton
            onClick={() => toggleFiltersMenu('sort')}
            sortByLabel={localiseText('find.sort_by')}
            selectedOptionLabel={
              sortByOptions.find(sortOption => sortOption.value === sortValue)
                ?.label || /* istanbul ignore next */ ''
            }
          />
        )}
        {enableRudeToggle && <RudeToggle />}
      </Flex>

      <ProductGridWithPaging
        totalCount={totalCount}
        region={region}
        products={products}
        loadMoreProducts={onLoadMoreCallback}
        onProductClicked={onProductClicked}
        onProductFirstClick={onProductFirstClick}
        onAddToBasket={onAddedToBasketWithTracking}
        isInternalUser={isInternalUser}
        metaTitle={metaTitle}
        pageType={pageType}
        groupCardProject={groupCardProject}
        promoTile={promoTile}
      />
    </StyledBox>
  )
}

export const ProductGridWithTracking = memo(ProductGridWithTrackingComponent)
